import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeOpenText, faAngleRight } from '@fortawesome/free-solid-svg-icons'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Juventae</h1>
        {/* <span className="underline"></span> */}
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href='https://soundcloud.com/juventae'>
            <FontAwesomeIcon icon={faSoundcloud} className="icon" />
            SoundCloud
          <FontAwesomeIcon icon={faAngleRight} className="arrow" />
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href='mailto:contact@juventae.net'>
            <FontAwesomeIcon icon={faEnvelopeOpenText} className="icon" />
            contact@juventae.net
          <FontAwesomeIcon icon={faAngleRight} className="arrow" />
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
